<template>
  <div id="service">
    <div class="service_pro">
      <productList
        v-if="product_list"
        class="p32"
        :product_list="product_list"
        :showDom="true"
      ></productList>
    </div>
    <div class="service_chooese">
      <div class="li flex-container" @click="goReturnReason(2)">
        <img src="@image/order/service_product.png" />
        <h2 class="flex1">我要退换货</h2>
        <span>退货和换货收到的商品</span>
      </div>

      <div class="li flex-container" @click="goReturnReason(1)">
        <img src="@image/order/service_money.png" />
        <h2 class="flex1">我要退款</h2>
        <span>没有收到货或协商同意不用退货只退款</span>
      </div>
    </div>
    <p class="service_tips">退换货说明</p>
  </div>
</template>
<script>
import productList from "../list/productList";
import { Toast } from "vant";

import { post } from "@get/http";
export default {
  name: "",
  data: () => ({
    product_list: [],
  }),
  components: {
    productList,
  },
  created() {
    Toast.loading({
      message: "加载中...",
      loadingType: "spinner",
      overlay: true,
      forbidClick: true,
      duration: 0,
    });
    let query = this.$route.query;
    if (query?.order_id && query?.rec_id) {
      this.getOrderInfo(query?.order_id, query?.rec_id);
      Toast.clear();
    }
  },
  methods: {
    goReturnReason(type) {
      let query = this.$route.query;
      this.$router.push({
        name: "OrderServiceReason",
        query: {
          type,
          order_id: query?.order_id,
          order_goods_id: query?.rec_id,
        },
      });
    },
    async getOrderInfo(order_id, order_goods_id) {
      let { goods } = await post("/v2.Memberfund/refund_form", {
        data: {
          order_id,
          order_goods_id,
        },
      });
      this.product_list = goods;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

#service {
  background: #ededed;
  height: 100%;
  padding-top: rem(16);
}

.service_pro {
  width: rem(750);
  height: rem(244);
  margin: 0 auto;
  background: #ffffff;
  border-radius: rem(40) rem(40) 0px 0px;
}

.service_chooese {
  background: #fff;
  padding: 0 rem(32);
  margin-top: rem(16);
  .li {
    align-items: center;
    background: url("~@image/me/icon_more.png") no-repeat center right;
    background-size: rem(12) rem(22);
    border-bottom: rem(1) solid #ebebeb;
    padding: rem(48) 0;
    &:last-of-type {
      border-bottom: none;
    }
    img {
      width: rem(52);
      height: rem(52);
      margin-right: rem(16);
    }
    h2 {
      color: #343434;

      font-size: rem(32);
      font-weight: bold;
    }
    span {
      font-size: rem(22);

      color: #808080;

      margin-right: rem(34);
    }
  }
}

.service_tips {
  color: #4a83f5;
  font-size: rem(22);
  font-weight: bold;

  text-align: center;
  margin-top: rem(66);
}
</style>
